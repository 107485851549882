-
<template>
  <div>
    <b-modal
      id="order-modal"
      modal-class="modal-class"
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      scrollable
    >
      <button class="close-btn" @click="hideOrderModal">
        <i class="fa fa-times"></i>
      </button>
      <div class="order-type">
        <div class="text-value">Order</div>
      </div>
      <div class="d-block text-center p-3 order-heading">
        <h3>Order #{{ orderDetails.inviteId }}:</h3>
      </div>

      <div class="order-info-details">
        <div class="info-block user-info">
          <img
            v-bind:src="
              orderDetails.rider.profileImage || '/media/users/user1.jpg'
            "
            alt="user image"
          />
          <span class="user-name">{{ orderDetails.rider.name }}</span>
          <span class="ratings-block">
            <i
              v-for="index in 5"
              class="fa fa-star"
              v-bind:class="
                getGoldClass(
                  orderDetails.rider
                    ? parseInt(orderDetails.rider.vendorRating)
                    : 0,
                  index
                )
              "
              :key="index"
            ></i>
          </span>
          <span class="ratings-value"
            >{{
              orderDetails.rider ? orderDetails.rider.vendorRating : "0"
            }}.0</span
          >
        </div>
        <div class="info-block order-info">
          <div class="product-info">
            No. of Products:
            <span>1</span>
          </div>
          <div class="bill-info">
            Bill:
            <span class="bill">PKR{{ orderDetails.estimatedAmount }}</span>
          </div>
          <div class="delivery-info">
            Delivery Time:
            <span>0</span>
          </div>
        </div>
        <div class="info-block status-block">
          <div class="status-container">
            <img
              src="/media/dashboard/green_smiley@2x.png"
              alt="Green Smiley"
            />
            <span class="status-text">{{ orderDetails.orderPerformance }}</span>
          </div>
        </div>
      </div>
      <div class="order-info-details">
        <status-graph-squad :order="orderDetails" />
      </div>

      <div class="billing-container" v-if="showMap" style="position: relative">
        <h3 class="heading">
          <span
            style="
              position: absolute;
              top: 0px;
              left: 25px;
              padding: 5px;
              cursor: pointer;
            "
            @click="toggleMap"
          >
            <i class="fas fa-chevron-left"></i>
          </span>
          Live Map
        </h3>
        <rider-map />
      </div>
      <div class="products-details-container" v-if="!showMap">
        <h3 class="heading">Customer Info</h3>
        <table class="table">
          <thead>
            <tr>
              <th>S. No</th>
              <th>Name</th>
              <th>Zone</th>
              <th>Quantity</th>
              <th>Estimated Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="index" v-for="(item, index) in orderDetails.deliveryInfo">
              <td>{{ index + 1 }}</td>
              <td style="font-weight: 700">
                <span>
                  <img
                    style="width: 35px; height: 35px; border-radius: 50%"
                    :src="`${
                      item.user.profileImage || '/media/users/user1.jpg'
                    }`"
                  />
                  {{ item.user.name }}
                </span>
              </td>
              <td>{{ item.zone }}</td>
              <td>{{ item.quantity }}</td>
              <td>PKR{{ item.estimatedAmount }}</td>
            </tr>
            <tr>
              <td>{{ orderDetails.deliveryInfo.length + 1 }}</td>
              <td>All</td>
              <td>-</td>
              <td>
                {{
                  orderDetails.deliveryInfo.reduce(
                    (acc, i) => i.quantity + acc,
                    0
                  )
                }}
              </td>
              <td>
                PKR{{
                  orderDetails.deliveryInfo.reduce(
                    (acc, i) => i.estimatedAmount + acc,
                    0
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="billing-container" v-if="!showMap">
        <h3 class="heading">Billing & Payments</h3>
        <div class="billing-content">
          <div class="billing-details details-section">
            <div class="billing-detail">
              <span class="heading">Total Bill</span>
              <span class="value">PKR{{ orderDetails.orderAmount }}</span>
            </div>
            <div class="billing-detail">
              <span class="heading">Delivery Charges</span>
              <span class="value">PKR{{ orderDetails.deliveryCharges }}</span>
            </div>

            <div class="billing-detail promo">
              <span class="heading">Promo Code</span>
              <span class="value">PKR0</span>
            </div>
            <div class="billing-detail">
              <span class="heading">Estimated Total</span>
              <span class="value"
                >PKR{{
                  orderDetails.estimatedAmount
                    ? orderDetails.estimatedAmount.toFixed(2)
                    : "0"
                }}</span
              >
            </div>
            <div
              class="billing-detail confirmed"
              :class="orderDetails.riderEarning === 0 ? 'pending' : ''"
            >
              <span class="heading">Confirmed payment</span>
              <span class="value">{{
                orderDetails.riderEarning === 0 ? "Pending" : "Confirmed"
              }}</span>
            </div>
          </div>
          <div class="billing-details earning">
            <div class="earning-block">
              <div class="title">Baskyt Payment</div>
              <img src="/media/dashboard/baskytor_card.png" alt="Float cart" />

              <div class="details">PKR{{ orderDetails.adminEarning }}</div>
            </div>
            <div class="earning-block">
              <div class="title">Baskytor Earning</div>
              <div class="logo">
                <img src="/media/dashboard/float_cart.png" alt="Float cart" />
              </div>
              <div class="details">PKR{{ orderDetails.riderEarning }}</div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RiderMap from "@/view/layout/dashboard_components/RiderMap";
import StatusGraphSquad from "@/view/pages/orders/us/StatusGraphSquad";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "OrderModelSquadPK",
  data() {
    return {
      lastseenNum: 1623782953450,
      showMap: false,
      customerNoteText: "",
      riderexist: false,
      retries: [],
      dropdown: false,
    };
  },
  components: {
    RiderMap,
    StatusGraphSquad,
  },
  computed: {
    ...mapState({
      region: (state) => state.region.isRegion,
    }),
  },
  props: {
    orderDetails: Object,
  },
  mounted() {
    console.log(
      "file: OrderModal.vue | line 373 | mounted | this.orderDetails.",
      this.orderDetails
    );

    // this.retries = this.orderDetails.retries;
    if (this.orderDetails.rider._id !== "") {
      this.riderexist = true;
    } else {
      this.riderexist = false;
    }
  },

  methods: {
    showDropdown() {
      if (this.dropdown) this.dropdown = false;
      else this.dropdown = true;
    },
    getProgessText(status) {
      if (status === undefined) return;
      if (status.toLowerCase() === "pending") {
        return "Finding Baskytor";
      } else if (status.toLowerCase() === "confirmed") {
        return "On the  way to the Store";
      } else if (
        status.toLowerCase() === "assembling" ||
        status.toLowerCase() === "preparing"
      ) {
        return "Assembling Products";
      } else if (status.toLowerCase() === "picked up") {
        return "On the way to Customer";
      } else {
        return "Delivered";
      }
    },
    toggleMap() {
      this.showMap = !this.showMap;
    },
    showOrderModal: function () {
      this.$bvModal.show("order-modal");
    },
    getGoldClass: function (total, key) {
      return key <= total ? "gold" : "";
    },
    hideOrderModal: function () {
      this.$bvModal.hide("order-modal");
      this.$emit("close");
    },
    humanizeDuration: function (duration) {
      return `In ${moment.duration(duration, "milliseconds").humanize()}.`;
    },
  },
};
</script>

<style lang="scss" scoped>
$gray: #9f9fa3;
$green: #3bb40c;
$orange: #ef8411;
::v-deep .modal-class {
  .no-Rider {
    // justify-content: center;
    text-align: center;
  }
  .modal-body {
    padding: 1.75rem 0;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #dc1828;
    }
  }
  .modal-dialog {
    .close-btn {
      background: none;
      position: absolute;
      right: 25px;
      top: 15px;
    }
    min-width: 750px;
    .modal-content {
      border-radius: 25px;
      .order-type {
        width: 100%;
        display: flex;
        justify-content: center;
        .text-value {
          background: #ffafb1;
          color: #e90008;
          font-size: 14px;
          font-weight: 700;
          padding: 5px 15px;
          border-radius: 25px;
        }
      }
      .order-heading {
        border-bottom: 1px solid #818181;
        h3 {
          font-weight: 600;
        }
        p {
          font-size: 14px;
        }
      }
      .order-info-details {
        padding: 15px;
        border-bottom: 1px solid #818181;
        display: grid;
        grid-template-columns: repeat(3, auto);
        .data-cell {
          display: flex;
          &.order-amount {
            display: flex;
            width: 100%;
            .value {
              font-weight: 700;
              width: 75px;
              margin-left: 5px;
            }
            .dropdown-toggle {
              background-color: transparent !important;
              border: none !important;
              padding: 0px !important;
              color: white;
            }
          }
        }
        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          &.user-info {
            img {
              border-radius: 50%;
              border: 1px solid red;
              height: 75px;
              width: 75px;
              margin: 5px 0;
            }
            .ratings-block {
              margin: 5px 0;
              display: flex;
              i {
                margin: 5px 0;
              }
              i.gold {
                color: #ff9500;
              }
            }
            .ratings-value {
              margin: 5px 0;
            }
          }
          &.order-info {
            align-items: flex-start;
            font-weight: 700;
            justify-content: center;
            .product-info {
              padding: 5px;
            }
            .bill-info {
              padding: 5px;
              span {
                text-decoration: underline;
                font-weight: 400;
              }
            }
            .delivery-info {
              padding: 5px;
              span {
                font-weight: 400;
              }
            }
            .retries-info {
              padding: 5px;
              div {
                span {
                  font-weight: 400;
                }
              }
            }
          }
          &.progress-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .ProgressBar {
              width: 100%;
              margin: 0 auto;
              padding: 10px;
              list-style: none;
              position: relative;
              display: flex;
              justify-content: space-between;
            }
            .ProgressBar-step.is-complete + .ProgressBar-step.is-current:after,
            .ProgressBar-step.is-complete
              + .ProgressBar-step.is-complete:after {
              width: 100%;
            }
            .ProgressBar-step {
              text-align: center;
              position: relative;
              width: 100%;

              &:before,
              &:after {
                content: "";
                height: 3px;
                background-color: $gray;
                position: absolute;
                z-index: 1;
                width: 100%;
                left: -50%;
                top: 55%;
                transform: translateY(-50%);
                transition: all 0.25s ease-out;
              }

              &:first-child:before,
              &:first-child:after {
                display: none;
              }

              &:after {
                background-color: $green;
                width: 0%;
              }
            }
            .is-current .ProgressBar-icon {
              fill: $orange;
              background-color: $orange;
            }
            .is-complete .ProgressBar-icon {
              fill: #fff;
              background-color: $green;
            }
            .ProgressBar-icon {
              width: 20px;
              height: 20px;
              background-color: $gray;
              fill: $gray;
              border-radius: 50%;
              padding: 5px;
              max-width: 100%;
              z-index: 10;
              position: relative;
              transition: all 0.25s ease-out;
            }

            .ProgressBar-stepLabel {
              display: block;
              text-transform: uppercase;
              color: $gray;
              position: absolute;
              padding-top: 8px;
              width: 100%;
              transition: all 0.25s ease-out;
            }
            .progress-text {
              color: $green;
              text-align: center;
            }
            .progress-eta {
              font-weight: 700;
              font-size: 12px;
            }
          }
          &.status-block {
            padding: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            .status-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              img {
                height: 35px;
                margin: 5px;
                width: auto;
              }
            }
          }
        }
      }
      .customer-details-container {
        border-bottom: 1px solid #818181;

        padding: 25px;
        .heading {
          text-align: center;
        }

        .customer-details-content {
          display: flex;
          .customer-details-block {
            width: 50%;
            .detail-block {
              padding: 5px;
              font-size: 12px;
              display: flex;
              .detail-heading {
                font-weight: 700;
                width: 50%;
                span {
                  cursor: pointer;
                }
              }
              .detail-text {
                width: 50%;
              }
            }
          }
        }
      }
      .products-details-container {
        border-bottom: 1px solid #818181;

        padding: 25px;
        .heading {
          text-align: center;
        }
        .product-details-row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          &.heading {
            font-weight: 700;
          }
          &.details {
            margin: 5px 0;
            .column {
              font-size: 12px;
              &:nth-child(2) {
                text-align: left;
              }
            }
          }
          .column {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &:nth-child(1) {
              text-align: left;
              justify-content: flex-start;
            }
            &:nth-child(2) {
              width: 125%;
            }
            .cat {
              padding: 5px;
              color: #019b25;
              background: #82f89d;
              border-radius: 25px;
              font-weight: 700;
              display: flex;
              justify-content: center;
              width: 100%;
            }
          }
        }
      }
      .billing-container {
        padding: 25px;
        .heading {
          text-align: center;
        }
        .billing-content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          .billing-details {
            &.details-section {
              display: flex;
              flex-direction: column;
              .billing-detail {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                margin: 5px 2.5px;
                .heading {
                  font-weight: 700;
                }
                &.promo {
                  color: #e9424c;
                }
                &.confirmed {
                  color: #019b25;
                  background: #82f89d;
                  padding: 5px;
                  border-radius: 25px;
                  margin: 5px 0px;
                }
                &.pending {
                  background: transparentize($color: $orange, $amount: 0.7);
                  color: $orange;
                }
              }
            }
            &.earning {
              width: 100%;
              display: flex;
              align-items: center;
              .earning-block {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .title {
                  font-size: 12px;
                  font-weight: 700;
                }
                img.logo {
                  height: 30px;
                  width: auto;
                }
                .details {
                  font-size: 12px;
                  font-weight: 700;
                  color: #707070;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
