var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"status-graph table table-borderless"},[_c('tr',{staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:_vm.order.status === 'Pending'
              ? (_vm.circleClass + "--blink")
              : (_vm.circleClass + "--done")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v("Accepted")]),_c('td',[_vm._v(" "+_vm._s(_vm.order.status === "Pending" ? "Pending" : _vm.$moment(_vm.order.timeAccepted).format("h:mm a"))+" ")]),_c('td')]),_c('tr',{staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:!['Pending', 'Confirmed'].includes(_vm.order.status)
              ? (_vm.circleClass + "--done")
              : (_vm.circleClass + "--pending")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v(_vm._s(_vm.order.store.storeName)+"[Reach]")]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment(_vm.order.timeReachedStore).format("h:mm a"))+" ")]),_c('td',[_vm._v(" "+_vm._s(!["Pending", "Confirmed"].includes(_vm.order.status) ? ("Reached in " + (_vm.$humanize( _vm.order.durationReachedStoreActual * 1000, { units: ["m"], round: true, } ))) : ("Expected to reach in " + (_vm.$humanize( _vm.order.durationReachedStoreExpected * 1000, { units: ["m"], round: true, } ))))+" ")])]),_c('tr',{staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:['Reached Vendor'].includes(_vm.order.status)
              ? (_vm.circleClass + "--blink")
              : !['Pending', 'Confirmed', 'Reached Vendor'].includes(
                  _vm.order.status
                )
              ? (_vm.circleClass + "--done")
              : (_vm.circleClass + "--pending")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v(" "+_vm._s(_vm.order.store.storeName)+"[Picked] ")]),_c('td',[_vm._v(" "+_vm._s(!["Pending", "Confirmed", "Reached Vendor"].includes(_vm.order.status) ? _vm.$moment(_vm.order.timePicked).format("h:mm a") : "Pending")+" ")]),_c('td',[_vm._v(" "+_vm._s(!["Pending", "Confirmed", "Reached Vendor"].includes(_vm.order.status) ? ("Picked in " + (_vm.$humanize(_vm.order.durationShoptimeActual * 1000, { units: ["m"], round: true, }))) : ("Expected to pick in " + (_vm.$humanize( _vm.order.durationShoptimeExpected * 1000, { units: ["m"], round: true, } ))))+" ")])]),_vm._l((_vm.order.customers),function(customer){return [_c('tr',{key:customer.customer._id,staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:customer.status === 'Reached' || customer.status === 'Delivered'
                ? (_vm.circleClass + "--done")
                : (_vm.circleClass + "--pending")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v(_vm._s(customer.customer.name))]),_c('td',[_vm._v(" "+_vm._s(customer.status === "Delivered" || customer.status === "Reached" ? _vm.$moment(customer.timeReached).format("h:mm a") : "Pending")+" ")]),_c('td',[_vm._v(" "+_vm._s(customer.status === "Delivered" || customer.status === "Reached" ? ("Reached in " + (_vm.$humanize(customer.durationReached * 1000, { units: ["m"], round: true, }))) : ("Expected to reach in " + (_vm.$humanize( customer.durationRemaining * 1000, { units: ["m"], round: true, } ))))+" ")])]),_c('tr',{key:customer.customer._id,staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:customer.status === 'Delivered'
                ? (_vm.circleClass + "--done")
                : customer.status === 'Reached'
                ? (_vm.circleClass + "--blink")
                : (_vm.circleClass + "--pending")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v(" "+_vm._s(customer.customer.name)+"[Delivered] ")]),_c('td',[_vm._v(" "+_vm._s(customer.status === "Delivered" ? _vm.$moment(customer.timeDelivered).format("h:mm a") : "Pending")+" ")]),_c('td',[_vm._v(" "+_vm._s(customer.status === "Delivered" ? ("Delivered in " + (_vm.$humanize(customer.durationDelivered * 1000, { units: ["m"], round: true, }))) : ("Expected to deliver in " + (_vm.$humanize( customer.durationRemaining * 1000, { units: ["m"], round: true, } ))))+" ")])])]}),_c('tr',{staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:_vm.order.status === 'Delivered'
              ? (_vm.circleClass + "--done")
              : (_vm.circleClass + "--pending")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v("Delivered")]),_c('td',[_vm._v(" "+_vm._s(_vm.order.status === "Delivered" ? _vm.$moment(_vm.order.timeDelivered).format("h:mm a") : "Pending")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.order.status === "Delivered" ? ("Delivered in " + (_vm.$humanize(_vm.order.durationDelivered * 1000, { units: ["m"], round: true, }))) : "Pending")+" ")])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }