<template>
  <div>
    <table class="status-graph table table-borderless">
      <tr class="status-graph__row">
        <td>
          <span
            class="status-graph__circle"
            :class="
              order.types.status === 'Pending'
                ? `${circleClass}--blink`
                : `${circleClass}--done`
            "
          ></span>
        </td>
        <td class="status-graph__status">Accepted</td>
        <td>
          {{
            order.types.status === "Pending"
              ? "Pending"
              : $moment(order.time.accepted).format("h:mm a")
          }}
        </td>
        <td></td>
      </tr>
      <template v-for="store in order.stores">
        <tr class="status-graph__row" :key="`${store.store._id}1`">
          <td>
            <span
              class="status-graph__circle"
              :class="
                store.riderStatus === 'Picked' ||
                store.riderStatus === 'Reached'
                  ? `${circleClass}--done`
                  : `${circleClass}--pending`
              "
            ></span>
          </td>
          <td class="status-graph__status">
            {{ store.store.storeName }}[Reach]
          </td>
          <td>
            {{ $moment(store.reachTime).format("h:mm a") }}
          </td>
          <td>
            {{
              store.riderStatus === "Reached" || store.riderStatus === "Picked"
                ? `Reached in ${$humanize(store.durationReached * 1000, {
                    units: ["m"],
                    round: true,
                  })}`
                : `Expected to reach in ${$humanize(
                    store.durationExpected * 1000,
                    {
                      units: ["m"],
                      round: true,
                    }
                  )}`
            }}
          </td>
        </tr>
        <tr class="status-graph__row" :key="`${store.store._id}2`">
          <td>
            <span
              class="status-graph__circle"
              :class="
                store.riderStatus === 'Reached'
                  ? `${circleClass}--blink`
                  : store.riderStatus === 'Picked'
                  ? `${circleClass}--done`
                  : `${circleClass}--pending`
              "
            ></span>
          </td>
          <td class="status-graph__status">
            {{ store.store.storeName }}[Picked]
          </td>
          <td>
            {{
              store.riderStatus === "Picked"
                ? $moment(store.pickedTime).format("h:mm a")
                : "Pending"
            }}
          </td>
          <td>
            {{
              store.riderStatus === "Picked"
                ? `Picked in ${$humanize(store.durationShopped * 1000, {
                    units: ["m"],
                    round: true,
                  })}`
                : `Expected to pick in ${$humanize(
                    store.durationExpected * 1000,
                    {
                      units: ["m"],
                      round: true,
                    }
                  )}`
            }}
          </td>
        </tr>
      </template>
      <tr class="status-graph__row">
        <td>
          <span
            class="status-graph__circle"
            :class="
              order.types.status === 'Delivered'
                ? `${circleClass}--done`
                : order.types.status === 'Reached Customer'
                ? `${circleClass}--blink`
                : `${circleClass}--pending`
            "
          ></span>
        </td>
        <td class="status-graph__status">{{ order.receiver.name }}</td>
        <td>
          {{
            order.types.status === "Delivered" ||
            order.types.status === "Reached Customer"
              ? $moment(order.time.reachedCustomer).format("h:mm a")
              : "Pending"
          }}
        </td>
        <td>
          {{
            order.types.status === "Delivered" ||
            order.types.status === "Reached Customer"
              ? `Reached in ${$humanize(order.durationReachedCustomer * 1000, {
                  units: ["m"],
                  round: true,
                })}`
              : `Expected to reach in ${$humanize(
                  order.durationExpected * 1000,
                  {
                    units: ["m"],
                    round: true,
                  }
                )}`
          }}
        </td>
      </tr>
      <tr class="status-graph__row">
        <td>
          <span
            class="status-graph__circle"
            :class="
              order.types.status === 'Delivered'
                ? `${circleClass}--done`
                : `${circleClass}--pending`
            "
          ></span>
        </td>
        <td class="status-graph__status">Delivered</td>
        <td>
          {{
            order.types.status === "Delivered"
              ? $moment(order.time.delivered).format("h:mm a")
              : "Pending"
          }}
        </td>
        <td>
          {{
            order.types.status === "Delivered"
              ? `Delivered in ${$humanize(order.durationDelivered * 1000, {
                  units: ["m"],
                  round: true,
                })}`
              : "Pending"
          }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "StatusGraphPK",
  props: {
    order: Object,
  },
  data: function () {
    return {
      circleClass: "status-graph__circle",
    };
  },
};
</script>

<style lang="scss" scoped></style>
