<template>
  <div>
    <table class="status-graph table table-borderless">
      <tr class="status-graph__row">
        <td>
          <span
            class="status-graph__circle"
            :class="
              order.status === 'Pending'
                ? `${circleClass}--blink`
                : `${circleClass}--done`
            "
          ></span>
        </td>
        <td class="status-graph__status">Accepted</td>
        <td>
          {{
            order.status === "Pending"
              ? "Pending"
              : $moment(order.timeAccepted).format("h:mm a")
          }}
        </td>
        <td></td>
      </tr>
      <tr class="status-graph__row">
        <td>
          <span
            class="status-graph__circle"
            :class="
              !['Pending', 'Confirmed'].includes(order.status)
                ? `${circleClass}--done`
                : `${circleClass}--pending`
            "
          ></span>
        </td>
        <td class="status-graph__status">{{ order.store.storeName }}[Reach]</td>
        <td>
          {{ $moment(order.timeReachedStore).format("h:mm a") }}
        </td>
        <td>
          {{
            !["Pending", "Confirmed"].includes(order.status)
              ? `Reached in ${$humanize(
                  order.durationReachedStoreActual * 1000,
                  {
                    units: ["m"],
                    round: true,
                  }
                )}`
              : `Expected to reach in ${$humanize(
                  order.durationReachedStoreExpected * 1000,
                  {
                    units: ["m"],
                    round: true,
                  }
                )}`
          }}
        </td>
      </tr>
      <tr class="status-graph__row">
        <td>
          <span
            class="status-graph__circle"
            :class="
              ['Reached Vendor'].includes(order.status)
                ? `${circleClass}--blink`
                : !['Pending', 'Confirmed', 'Reached Vendor'].includes(
                    order.status
                  )
                ? `${circleClass}--done`
                : `${circleClass}--pending`
            "
          ></span>
        </td>
        <td class="status-graph__status">
          {{ order.store.storeName }}[Picked]
        </td>
        <td>
          {{
            !["Pending", "Confirmed", "Reached Vendor"].includes(order.status)
              ? $moment(order.timePicked).format("h:mm a")
              : "Pending"
          }}
        </td>
        <td>
          {{
            !["Pending", "Confirmed", "Reached Vendor"].includes(order.status)
              ? `Picked in ${$humanize(order.durationShoptimeActual * 1000, {
                  units: ["m"],
                  round: true,
                })}`
              : `Expected to pick in ${$humanize(
                  order.durationShoptimeExpected * 1000,
                  {
                    units: ["m"],
                    round: true,
                  }
                )}`
          }}
        </td>
      </tr>
      <template v-for="customer in order.customers">
        <tr class="status-graph__row" :key="customer.customer._id">
          <td>
            <span
              class="status-graph__circle"
              :class="
                customer.status === 'Reached' || customer.status === 'Delivered'
                  ? `${circleClass}--done`
                  : `${circleClass}--pending`
              "
            ></span>
          </td>
          <td class="status-graph__status">{{ customer.customer.name }}</td>
          <td>
            {{
              customer.status === "Delivered" || customer.status === "Reached"
                ? $moment(customer.timeReached).format("h:mm a")
                : "Pending"
            }}
          </td>
          <td>
            {{
              customer.status === "Delivered" || customer.status === "Reached"
                ? `Reached in ${$humanize(customer.durationReached * 1000, {
                    units: ["m"],
                    round: true,
                  })}`
                : `Expected to reach in ${$humanize(
                    customer.durationRemaining * 1000,
                    {
                      units: ["m"],
                      round: true,
                    }
                  )}`
            }}
          </td>
        </tr>
        <tr class="status-graph__row" :key="customer.customer._id">
          <td>
            <span
              class="status-graph__circle"
              :class="
                customer.status === 'Delivered'
                  ? `${circleClass}--done`
                  : customer.status === 'Reached'
                  ? `${circleClass}--blink`
                  : `${circleClass}--pending`
              "
            ></span>
          </td>
          <td class="status-graph__status">
            {{ customer.customer.name }}[Delivered]
          </td>
          <td>
            {{
              customer.status === "Delivered"
                ? $moment(customer.timeDelivered).format("h:mm a")
                : "Pending"
            }}
          </td>
          <td>
            {{
              customer.status === "Delivered"
                ? `Delivered in ${$humanize(customer.durationDelivered * 1000, {
                    units: ["m"],
                    round: true,
                  })}`
                : `Expected to deliver in ${$humanize(
                    customer.durationRemaining * 1000,
                    {
                      units: ["m"],
                      round: true,
                    }
                  )}`
            }}
          </td>
        </tr>
      </template>
      <tr class="status-graph__row">
        <td>
          <span
            class="status-graph__circle"
            :class="
              order.status === 'Delivered'
                ? `${circleClass}--done`
                : `${circleClass}--pending`
            "
          ></span>
        </td>
        <td class="status-graph__status">Delivered</td>
        <td>
          {{
            order.status === "Delivered"
              ? $moment(order.timeDelivered).format("h:mm a")
              : "Pending"
          }}
        </td>
        <td>
          {{
            order.status === "Delivered"
              ? `Delivered in ${$humanize(order.durationDelivered * 1000, {
                  units: ["m"],
                  round: true,
                })}`
              : "Pending"
          }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "StatusGraphSquadUS",
  props: {
    order: Object,
  },
  data: function () {
    return {
      circleClass: "status-graph__circle",
    };
  },
};
</script>

<style lang="scss" scoped></style>
