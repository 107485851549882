var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"status-graph table table-borderless"},[_c('tr',{staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:_vm.order.types.status === 'Pending'
              ? (_vm.circleClass + "--blink")
              : (_vm.circleClass + "--done")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v("Accepted")]),_c('td',[_vm._v(" "+_vm._s(_vm.order.types.status === "Pending" ? "Pending" : _vm.$moment(_vm.order.time.accepted).format("h:mm a"))+" ")]),_c('td')]),_vm._l((_vm.order.stores),function(store){return [_c('tr',{key:((store.store._id) + "1"),staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:store.riderStatus === 'Picked' ||
              store.riderStatus === 'Reached'
                ? (_vm.circleClass + "--done")
                : (_vm.circleClass + "--pending")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v(" "+_vm._s(store.store.storeName)+"[Reach] ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment(store.reachTime).format("h:mm a"))+" ")]),_c('td',[_vm._v(" "+_vm._s(store.riderStatus === "Reached" || store.riderStatus === "Picked" ? ("Reached in " + (_vm.$humanize(store.durationReached * 1000, { units: ["m"], round: true, }))) : ("Expected to reach in " + (_vm.$humanize( store.durationExpected * 1000, { units: ["m"], round: true, } ))))+" ")])]),_c('tr',{key:((store.store._id) + "2"),staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:store.riderStatus === 'Reached'
                ? (_vm.circleClass + "--blink")
                : store.riderStatus === 'Picked'
                ? (_vm.circleClass + "--done")
                : (_vm.circleClass + "--pending")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v(" "+_vm._s(store.store.storeName)+"[Picked] ")]),_c('td',[_vm._v(" "+_vm._s(store.riderStatus === "Picked" ? _vm.$moment(store.pickedTime).format("h:mm a") : "Pending")+" ")]),_c('td',[_vm._v(" "+_vm._s(store.riderStatus === "Picked" ? ("Picked in " + (_vm.$humanize(store.durationShopped * 1000, { units: ["m"], round: true, }))) : ("Expected to pick in " + (_vm.$humanize( store.durationExpected * 1000, { units: ["m"], round: true, } ))))+" ")])])]}),_c('tr',{staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:_vm.order.types.status === 'Delivered'
              ? (_vm.circleClass + "--done")
              : _vm.order.types.status === 'Reached Customer'
              ? (_vm.circleClass + "--blink")
              : (_vm.circleClass + "--pending")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v(_vm._s(_vm.order.receiver.name))]),_c('td',[_vm._v(" "+_vm._s(_vm.order.types.status === "Delivered" || _vm.order.types.status === "Reached Customer" ? _vm.$moment(_vm.order.time.reachedCustomer).format("h:mm a") : "Pending")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.order.types.status === "Delivered" || _vm.order.types.status === "Reached Customer" ? ("Reached in " + (_vm.$humanize(_vm.order.durationReachedCustomer * 1000, { units: ["m"], round: true, }))) : ("Expected to reach in " + (_vm.$humanize( _vm.order.durationExpected * 1000, { units: ["m"], round: true, } ))))+" ")])]),_c('tr',{staticClass:"status-graph__row"},[_c('td',[_c('span',{staticClass:"status-graph__circle",class:_vm.order.types.status === 'Delivered'
              ? (_vm.circleClass + "--done")
              : (_vm.circleClass + "--pending")})]),_c('td',{staticClass:"status-graph__status"},[_vm._v("Delivered")]),_c('td',[_vm._v(" "+_vm._s(_vm.order.types.status === "Delivered" ? _vm.$moment(_vm.order.time.delivered).format("h:mm a") : "Pending")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.order.types.status === "Delivered" ? ("Delivered in " + (_vm.$humanize(_vm.order.durationDelivered * 1000, { units: ["m"], round: true, }))) : "Pending")+" ")])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }